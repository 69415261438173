import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  checkIsFullLocationPermissionRequired,
  getCurrentLocationStatus,
  getPreviousLocationPermissionStatus,
  isOsWithoutAlwaysAllowOption,
} from "@src/appV2/Location/utils";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useEffect } from "react";

import { useAppStateContext } from "../App/useAppState";
import { APP_V2_APP_EVENTS, isAndroidPlatform } from "../lib";
import { logEvent } from "../lib/analytics";
import { requestFullLocationAccess } from "../Location/utils/permissions";
import { LocationPermissionsBottomSheet } from "../redesign/Shift/Bookability/LocationPermissionsBottomSheet";
import { useNextTwoDaysShifts } from "../Shifts/MyShifts/api/useNextTwoDaysShifts";
import { type Shift } from "../Shifts/Shift/types";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import { AlwaysAllowLocationDialog } from "./AlwaysAllowLocationDialog/AlwaysAllowLocationDialog";
import { useUpdateWorker } from "./api/useUpdateWorker";

interface EnforceLocationPermissionsContainerProps {
  /**
   * Whether to show legacy dialog or redesigned bottom sheet.
   * The redesigned bottom sheet captures taps near the bottom edge
   * which causes issues in tests of the old UI.
   */
  useLegacyDialog?: boolean;
}

export function EnforceLocationPermissionsContainer(
  props: EnforceLocationPermissionsContainerProps
) {
  const { useLegacyDialog = false } = props;
  const worker = useDefinedWorker();
  const {
    state: { isAppActive },
  } = useAppStateContext();
  const permissionModalState = useModalState();

  const { data: isOsWithoutAlwaysAllow = false } = useQuery({
    queryKey: ["isOsWithoutAlwaysAllow"],
    queryFn: async () => {
      return await isOsWithoutAlwaysAllowOption();
    },
    enabled: permissionModalState.modalIsOpen && isAppActive,
  });

  const { data: nextTwoDaysShiftsData, isError: isNextTwoDaysShiftsError } = useNextTwoDaysShifts(
    worker.tmz ?? "",
    {
      enabled: isDefined(worker.tmz),
    }
  );

  const { mutateAsync: handleUpdateAgent } = useUpdateWorker();

  async function updateLocationServiceStatus(shiftList: Shift[], shiftListError: boolean) {
    const currentLocationPermissionStatus = await getCurrentLocationStatus();
    const previousLocationPermissionsStatus = await getPreviousLocationPermissionStatus(worker);
    const updateWorkerPayload = Object.fromEntries(
      Object.entries(currentLocationPermissionStatus).map(([key, value]) => [
        `mobile.${key}`,
        value,
      ])
    );
    const dataUpdated = Object.keys(currentLocationPermissionStatus).some(
      (key) =>
        currentLocationPermissionStatus[key as keyof typeof currentLocationPermissionStatus] !==
        previousLocationPermissionsStatus?.[key as keyof typeof currentLocationPermissionStatus]
    );

    const requiresFullLocationPermission = await checkIsFullLocationPermissionRequired(
      currentLocationPermissionStatus
    );

    if (dataUpdated && !isEmpty(updateWorkerPayload)) {
      void handleUpdateAgent({
        agentId: worker.userId,
        currentLocationPermissionStatus,
        updateWorkerPayload,
      });
      logEvent(APP_V2_APP_EVENTS.WORKER_UPDATED_LOCATION_SETTINGS, {
        agentId: worker.userId,
        previousPermission: previousLocationPermissionsStatus,
        ...currentLocationPermissionStatus,
      });
    }

    if (
      requiresFullLocationPermission &&
      (shiftListError ||
        shiftList.length > 0 ||
        // This is required for Google Play approvals. The worker with id 615e2b664a11f8016b952c52 is a test worker.
        (worker.userId === "615e2b664a11f8016b952c52" && isAndroidPlatform()))
    ) {
      permissionModalState.openModal();
    } else {
      permissionModalState.closeModal();
    }
  }

  useEffect(() => {
    if (isAppActive) {
      void updateLocationServiceStatus(
        nextTwoDaysShiftsData?.response ?? [],
        isNextTwoDaysShiftsError
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTwoDaysShiftsData?.response, isNextTwoDaysShiftsError, isAppActive]);

  return useLegacyDialog ? (
    <AlwaysAllowLocationDialog
      modalState={permissionModalState}
      isOsWithoutAlwaysAllow={isOsWithoutAlwaysAllow}
      onConfirm={() => {
        permissionModalState.closeModal();
        void requestFullLocationAccess();
      }}
    />
  ) : (
    <LocationPermissionsBottomSheet
      recentlyDisabled
      modalState={permissionModalState}
      isNotNowButtonVisible={false}
    />
  );
}
