import { Preferences } from "@capacitor/preferences";
import { put } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import type { PermissionUpdate } from "@src/appV2/Location/utils";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { UrgentShiftLocalStorage } from "../../Location/constant";
import { useDefinedWorker } from "../../Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "../../Worker/useUpdateWorkerCache";

interface UpdateAgentParams {
  agentId: string;
  currentLocationPermissionStatus: PermissionUpdate;
  updateWorkerPayload: Record<string, string | boolean>;
}

const updateAgentResponseSchema = z.object({
  mobile: z.object({
    preciseLocation: z.boolean(),
    isLocationServicesEnabled: z.boolean(),
    locationServicesPermission: z.string(),
  }),
  _id: z.string(),
  userId: z.string(),
});
export type UpdateAgentResponse = z.infer<typeof updateAgentResponseSchema>;

export async function updateAgentData(
  agentId: string,
  updateWorkerPayload: UpdateAgentParams["updateWorkerPayload"]
) {
  try {
    return await put({
      url: `${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/put`,
      data: { agentId, ...updateWorkerPayload },
      responseSchema: updateAgentResponseSchema,
    });
  } catch (error) {
    logError(APP_V2_APP_EVENTS.UPDATE_AGENT_DATA_FAILURE, {
      error,
      metadata: { updateWorkerPayload, agentId },
    });
    throw error;
  }
}

export function useUpdateWorker() {
  const worker = useDefinedWorker();
  const updateWorkerCache = useUpdateWorkerCache();

  return useMutation({
    mutationFn: async (parameters: UpdateAgentParams) => {
      const { agentId, currentLocationPermissionStatus, updateWorkerPayload } = parameters;
      await Preferences.set({
        key: UrgentShiftLocalStorage.LAST_LOCATION_PERMISSION_STATUS,
        value: JSON.stringify({ ...currentLocationPermissionStatus, agentId: worker.userId }),
      });

      await updateAgentData(agentId, updateWorkerPayload);

      return currentLocationPermissionStatus;
    },
    onSuccess: (currentLocationPermissionStatus) => {
      updateWorkerCache({
        mobile: {
          ...worker.mobile,
          ...currentLocationPermissionStatus,
        },
      });
    },
  });
}
