import { Geolocation, type Position } from "@capacitor/geolocation";
import { type UseGetQueryOptions } from "@src/appV2/api";
import { APP_V2_APP_EVENTS, isCapacitorPlatform } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useQuery } from "@tanstack/react-query";

import { GET_GEOLOCATION_TIMEOUT_IN_MILLISECONDS } from "../constant";
import { useCheckAlwaysAllowLocationSettingIsEnabled } from "../hooks/featureFlagHooks";
import { type GeoLocation } from "../types";
import {
  isDeviceLocationAlwaysAllowPermissionGranted,
  isDeviceLocationPermissionGranted,
} from "./permissions";

interface GetDeviceGeoLocationProps {
  /**
   * Defaults to GET_GEOLOCATION_TIMEOUT_IN_MILLISECONDS (6 seconds)
   */
  timeoutInMilliseconds?: number;
  /**
   * Defaults to Capacitor default
   */
  maximumAgeInMilliseconds?: number;
}
export interface DeviceGeoLocation extends Pick<Position, "timestamp"> {
  geoLocation: GeoLocation;
}

export async function getDeviceGeoLocation(
  props?: GetDeviceGeoLocationProps
): Promise<DeviceGeoLocation> {
  const { timeoutInMilliseconds, maximumAgeInMilliseconds } = props ?? {};
  const position = await Geolocation.getCurrentPosition({
    enableHighAccuracy: true,
    timeout: timeoutInMilliseconds ?? GET_GEOLOCATION_TIMEOUT_IN_MILLISECONDS,
    maximumAge: maximumAgeInMilliseconds,
  });
  return {
    geoLocation: position.coords,
    timestamp: position.timestamp,
  };
}

export async function getDeviceGeoLocationIfAllowed(): Promise<DeviceGeoLocation | undefined> {
  if (!isCapacitorPlatform()) {
    return undefined;
  }

  if (!(await isDeviceLocationPermissionGranted())) {
    return undefined;
  }

  return await getDeviceGeoLocation();
}

export function useDeviceGeoLocationIfAllowed(
  options?: Pick<UseGetQueryOptions<DeviceGeoLocation | undefined>, "enabled">
) {
  return useQuery({
    queryKey: ["getDeviceGeoLocationIfAllowed"],
    queryFn: async () => {
      return (await getDeviceGeoLocationIfAllowed()) ?? null;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_DEVICE_GEOLOCATION_IF_ALLOWED_FAILURE,
    },
    ...options,
  });
}

export function useIsDeviceLocationPermissionGranted() {
  const shouldCheckAlwaysAllowSetting = useCheckAlwaysAllowLocationSettingIsEnabled();
  return useQuery({
    queryKey: ["isDeviceLocationPermissionGranted"],
    queryFn: async () => {
      if (!isCapacitorPlatform()) {
        return null;
      }

      if (shouldCheckAlwaysAllowSetting) {
        return await isDeviceLocationAlwaysAllowPermissionGranted();
      }

      logEvent(APP_V2_APP_EVENTS.SKIP_ALWAYS_ALLOW_DEVICE_SETTING_CHECK, {
        shouldCheckAlwaysAllowSetting,
      });

      return await isDeviceLocationPermissionGranted();
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.IS_DEVICE_LOCATION_PERMISSION_GRANTED_FAILURE,
    },
    /**
     * Setting cacheTime to 0 to prevent returning cached value
     * while re-fetching.
     */
    cacheTime: 0,
    /**
     * Setting staleTime to 0 to make the query re-fetch always.
     */
    staleTime: 0,
  });
}
