import { ExternalLink, type UseModalState } from "@clipboard-health/ui-react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { isIosPlatform } from "@src/appV2/lib";

interface AlwaysAllowLocationDialogProps {
  modalState: UseModalState;
  onConfirm: () => void;
  isOsWithoutAlwaysAllow: boolean;
}

export function AlwaysAllowLocationDialog(props: AlwaysAllowLocationDialogProps) {
  const { modalState, onConfirm, isOsWithoutAlwaysAllow } = props;

  return (
    <Dialog fullWidth open={modalState.modalIsOpen} maxWidth="xs">
      <DialogTitle>
        <Box display="flex" justifyContent="center" py={2}>
          <img src="assets/logo/bunny.png" alt="Clipboard logo" width={100} />
        </Box>
        Enable location permissions
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isOsWithoutAlwaysAllow ? (
            <>
              Your location permissions were recently disabled for the Clipboard app. Please go to
              your device&apos;s settings and enable <b>Location</b> permission so you can keep
              booking shifts on the Clipboard marketplace.
            </>
          ) : (
            <>
              Your location permissions were recently disabled for the Clipboard app. Please go to
              your device&apos;s settings and enable the{" "}
              <b>{isIosPlatform() ? "Always" : "Allow all the time"}</b> and <b>Precise Location</b>{" "}
              permissions so you can keep booking shifts on the Clipboard marketplace.
            </>
          )}
          <br />
          <br />
          We collect this location information, including in the background, to confirm to
          facilities that you are on your way to an upcoming shift, show you nearby Urgent Shifts,
          and verify your completion of shifts in order to distribute your earnings via InstantPay.
          <br />
          <br />
          To learn more about how we use location data, as well as your options to control your
          personal information, please review our{" "}
          <ExternalLink
            target="_blank"
            to="https://clipboardhealth.com/privacy-policy"
            rel="noreferrer"
          >
            Privacy Policy
          </ExternalLink>
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onConfirm();
          }}
        >
          Go To Settings
        </Button>
      </DialogActions>
    </Dialog>
  );
}
